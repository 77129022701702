import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { EnvelopeFill, GeoAltFill, TelephoneFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <Container>
        <Row className="top_part">
          <Col xs={12} md={4} xl={4}>
            <h5>Services</h5>
            <ul>
              <li>
                <Link> ERP Implementation</Link>
              </li>
              <li>
                <Link>Network Installation</Link>
              </li>
              <li>
                <Link>Data Security Managment</Link>
              </li>
              <li>
                <Link>Computer Hardware Sales</Link>
              </li>
              <li>
                <Link>ICT & IOT Consultancy</Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} xl={4}>
            <h5>Quick Links</h5>
            <ul>
              <li>
                <a href="#home">About Us</a>
              </li>
              <li>
                <a href="#our_clients">Our Clients</a>
              </li>
              <li>
                <a href="#what_we_do">What We Do</a>
              </li>
              <li>
                <a href="#why_choose_us">Why Choose Us</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4} xl={4}>
            <h5>Get In Touch</h5>
            <p>
              <Link
                to={
                  "https://www.google.com/maps/place/1%C2%B016'38.1%22S+36%C2%B049'44.9%22E/@-1.2772528,36.8265728,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-1.2772528!4d36.8291477?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%3D%3D"
                }
                target="_blank"
              >
                <GeoAltFill size={12} /> YTL(Tigoni) Centre, Ngara Rd, Nairobi{" "}
              </Link>
            </p>
            <p>
              <TelephoneFill size={12} /> +254-720-813-499
            </p>

            <p>
              <a href="mailto:techs@leveragesolutions.co.ke">
                <EnvelopeFill size={12} /> techs@leveragesolutions.co.ke
              </a>
            </p>
          </Col>
        </Row>
        <Row className="bottom_part">
          <Col xs={12} md={12} xl={12}>
            <p>&copy; {year} Leverage Solutions. All Rights Reserved. </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
